import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { flow } from 'lodash/fp'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { axiosBaseQuery } from './shared'

export const ManageAccountApiSliceKey = 'api.manageAccount'
export type ManageAccountApiReducerState = {
  [ManageAccountApiSliceKey]: ReturnType<typeof manageAccountApi.reducer>
}

const getApiBaseUrl = (state: AppState) => {
  const base = flow(getRockefellerApiConfig, (x) => x?.root)(state)
  const v1 = new URL('', base)
  return v1.href
}

const getApiAuthToken = (state: AppState) => {
  const scopes = flow(getRockefellerApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export type ManageAccountApiTagType = 'manageAccount'
export const manageAccountApiTagTypes: ManageAccountApiTagType[] = [
  'manageAccount'
]
export const manageAccountApi = createApi({
  reducerPath: ManageAccountApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getApiBaseUrl(state),
    getAuthToken: (state) => getApiAuthToken(state)
  }),
  endpoints: () => ({}),
  tagTypes: manageAccountApiTagTypes
})
