import { manageAccountApi } from 'store/api/manageAccount'
import {
  RestrictionRequest,
  RestrictionSubmitResponse,
  RestrictionTypeItem
} from '../store/types'

const RestrictionApiSlice = manageAccountApi.injectEndpoints({
  endpoints: (builder) => ({
    fetchRestrictions: builder.query<RestrictionTypeItem[], string>({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const url = `accountmaintanence/api/Restriction/code?Account=${_args}`

        const response = await baseQuery({
          url: url,
          method: 'GET'
        })
        try {
          if (response.error || (response?.data as any)?.error) {
            return { error: { status: 500, data: undefined } }
          }
          const list = response.data as RestrictionTypeItem[]

          return {
            data: list || []
          }
        } catch (error) {
          return {
            data: []
          }
        }
      },
      keepUnusedDataFor: 0
    }),
    submitRestrictions: builder.mutation<
      RestrictionSubmitResponse,
      RestrictionRequest
    >({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const url = `accountmaintanence/api/Restriction`

        const response = await baseQuery({
          url: url,
          method: 'POST',
          data: _args
        })
        try {
          if (response.error || (response?.data as any)?.error) {
            return { error: { status: 500, data: undefined } }
          }
          const resp = response.data as RestrictionSubmitResponse
          if (resp?.status === 200) {
            return {
              data: resp
            }
          } else {
            return { error: { status: 500, data: undefined } }
          }
        } catch (e) {
          return { error: { status: 500, data: undefined } }
        }
      }
    })
  })
})
export const {
  useLazyFetchRestrictionsQuery,
  useFetchRestrictionsQuery,
  useSubmitRestrictionsMutation
} = RestrictionApiSlice
