import { manageAccountApi } from 'store/api/manageAccount'
import {
  StoreDocumentRequest,
  IDocItem,
  ViewDocumentRequest,
  StoreDocumentResponse
} from '../store/types'

const DocumentsApiSlice = manageAccountApi.injectEndpoints({
  endpoints: (builder) => ({
    storeDocument: builder.query<IDocItem, StoreDocumentRequest>({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const url = 'documentsapi/api/FileData/StoreDocument'
        const data = new FormData()
        data.append('filecontents', _args.filecontents)
        data.append('channel', _args?.channel)
        const response = await baseQuery({
          url: url,
          method: 'POST',
          data: data
        })

        if (response.error) {
          return { error: { status: 500, data: undefined } }
        }
        const storeDocResponse = response.data as StoreDocumentResponse
        return {
          data: {
            storageKey: storeDocResponse?.storagedockey,
            storageDocId: storeDocResponse?.storagedocid,
            docName: _args?.filecontents?.name,
            documentType: _args?.doctype?.documentType,
            idDocument: _args?.doctype?.idDocument?.toString()
          } as IDocItem
        }
      }
    }),
    fetchDocument: builder.query<string, ViewDocumentRequest>({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const url = `documentsapi/api/FileData/GetDocument?storagedockey=${
          _args?.storagedockey
        }&storagedocid=${_args?.storagedocid || ''}`
        const response = await baseQuery({
          url: url,
          method: 'GET'
        })

        if (response.error) {
          return { error: { status: 500, data: undefined } }
        }
        const viewDocResponse = response.data as string

        return {
          data: viewDocResponse
        }
      }
    }),
    deleteDocument: builder.query<unknown, ViewDocumentRequest>({
      queryFn: async (_args, _api, _extraOptions, baseQuery) => {
        const url = `documentsapi/api/Filedata/DeleteDocument`

        const response = await baseQuery({
          url: url,
          method: 'POST',
          data: {
            storagedockey: _args.storagedockey,
            storagedocid: _args.storagedocid
          }
        })

        if (response.error) {
          return { error: { status: 500, data: undefined } }
        }
        const docResponse = response.data as string

        return {
          data: docResponse
        }
      }
    })
  })
})
export const {
  useLazyStoreDocumentQuery,
  useLazyFetchDocumentQuery,
  useLazyDeleteDocumentQuery
} = DocumentsApiSlice
