import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit'
import { IDataFilter } from 'shared/components/DataTableFilters/DataTableFilters'
import { AppState } from 'store'

interface IRestrictionsState {
  searchText?: string
  filter?: Dictionary<IDataFilter>
  sortOrder?: ISortOrder
  isAutoRefreshEnabled?: boolean
}

export interface ISortOrder {
  columnkey: string
  sortOrder: boolean
}

const initialState: IRestrictionsState = {
  filter: {},
  searchText: '',
  isAutoRefreshEnabled: true
}

const Slice = createSlice({
  name: '@module/manageaccount/restrictions',
  initialState,
  reducers: {
    setDataFilters: (state, action: PayloadAction<Dictionary<IDataFilter>>) => {
      return { ...state, filter: { ...action.payload } }
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      return { ...state, searchText: action.payload }
    },
    setDashboardSortOrder: (
      state,
      action: PayloadAction<{ columnkey: string; sortOrder: boolean }>
    ) => {
      return { ...state, sortOrder: action.payload }
    },
    setAutoRefresh: (state, action: PayloadAction<boolean>) => {
      return { ...state, isAutoRefreshEnabled: action.payload }
    }
  }
})

export const {
  setDataFilters,
  setSearchText,
  setDashboardSortOrder,
  setAutoRefresh
} = Slice.actions
export const getRestrictionsFilter = (state: AppState) =>
  state.modules.manageaccount.restriction?.filter || {}
export const getSearchText = (state: AppState) =>
  state.modules.manageaccount.restriction?.searchText || ''
export const getSortOrder = (state: AppState) =>
  state.modules.manageaccount.restriction?.sortOrder
export const getAutoRefresh = (state: AppState) =>
  state.modules.manageaccount.restriction?.isAutoRefreshEnabled
export default Slice.reducer
