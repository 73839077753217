import {
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  Label,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
  ProgressIndicator,
  Stack
} from '@fluentui/react'
import { format } from 'date-fns'
import { useCallback, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSubmitRestrictionsMutation } from './api/restrictionApi'
import RestrictionSubmitForm from './RestrictionSubmitForm'
import { useManageAccountPanel } from './store/manageAccountPanel'
import { IRestriction } from './store/types'

export const ManageAccountPanel: React.FC = () => {
  const { isOpen, close, account } = useManageAccountPanel()
  const formMethods = useForm<IRestriction>()
  const { reset, formState } = formMethods
  const [isCloseConfirmationOpen, setIsCloseConfirmationOpen] = useState<
    boolean | undefined
  >(false)

  const [
    submitRestriction,
    { isSuccess, isLoading, isError, reset: resetSubmit }
  ] = useSubmitRestrictionsMutation()
  useEffect(() => {
    if (!isOpen) {
      reset()
      resetSubmit()
    }
  }, [reset, isOpen, resetSubmit])
  const onSubmit = useCallback(
    (data: IRestriction) => {
      let auxData = undefined
      try {
        if (
          data?.selectedRestriction?.auxFields?.[0]?.idAuxField &&
          data?.auxField
        ) {
          const isDate =
            data?.selectedRestriction?.auxFields?.[0]?.type?.toLowerCase() ===
            'date'
          auxData = [
            {
              auxField: {
                idAuxField:
                  data?.selectedRestriction?.auxFields?.[0]?.idAuxField,
                name: data?.selectedRestriction?.auxFields?.[0]?.name
              },
              value: isDate
                ? format(data?.auxField, 'MM/dd/yyyy')
                : data?.auxField?.toString()
            }
          ]
        }
      } catch (e) {
        console.log(e)
      }
      submitRestriction({
        idRestrictionAction:
          data?.selectedRestriction?.restrictionAction?.idRestrictionAction,
        account: account?.CustodyAccount,
        status: 'SUBMITTED',
        reason: data?.reason,
        notes: data?.notes,
        auxData: auxData,
        documents: data?.docInfo
      })
    },
    [account?.CustodyAccount, submitRestriction]
  )
  const onDismiss = useCallback(() => {
    if (formState?.isDirty && !isSuccess && !isError) {
      setIsCloseConfirmationOpen(true)
    } else {
      close()
    }
  }, [close, formState?.isDirty, isError, isSuccess])
  const onRenderFooterContent = useCallback(() => {
    return (
      <Stack horizontal={true} tokens={{ childrenGap: 10 }}>
        <PrimaryButton
          onClick={formMethods.handleSubmit(onSubmit)}
          disabled={isLoading}
        >
          Submit
        </PrimaryButton>
        <DefaultButton onClick={onDismiss}>Cancel</DefaultButton>
      </Stack>
    )
  }, [formMethods, isLoading, onDismiss, onSubmit])
  return (
    <>
      {isOpen && (
        <Panel
          headerText="Manage Restrictions"
          isOpen={isOpen}
          onDismiss={onDismiss}
          isFooterAtBottom={true}
          onRenderFooterContent={onRenderFooterContent}
          closeButtonAriaLabel="Close"
          type={PanelType.custom}
          customWidth="660px"
          styles={{ content: { flexGrow: 1 } }}
        >
          <FormProvider {...formMethods}>
            {isLoading && <ProgressIndicator />}
            {isSuccess && (
              <MessageBar messageBarType={MessageBarType.success}>
                Restriction submitted successfully !
              </MessageBar>
            )}
            {isError && (
              <MessageBar messageBarType={MessageBarType.error}>
                Error: Unable to submit the restriction!
              </MessageBar>
            )}
            <RestrictionSubmitForm />
          </FormProvider>
        </Panel>
      )}
      <Dialog
        hidden={!isCloseConfirmationOpen}
        onDismiss={() => setIsCloseConfirmationOpen(false)}
        dialogContentProps={{
          type: DialogType.largeHeader,
          title: 'Warning',
          closeButtonAriaLabel: 'Close'
        }}
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Label>
            There are unsaved changes. Are you sure you want to close?
          </Label>
        </Stack>
        <DialogFooter>
          <PrimaryButton
            onClick={() => {
              setIsCloseConfirmationOpen(false)
              close()
            }}
            text="Yes"
          />
          <DefaultButton
            onClick={() => setIsCloseConfirmationOpen(false)}
            text="No"
          />
        </DialogFooter>
      </Dialog>
    </>
  )
}
