import { lazy } from 'react'
import { IModuleDefinition } from '../types'

const ManageAccountContainer = lazy(() => import('./ManageAccountContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/manageaccount/*',
    private: true,
    component: ManageAccountContainer
  },
  name: 'Account Management'
}

export default moduleDefinition
