import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAccount } from 'api/account.types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppState } from 'store'

interface IManageAccountPanelState {
  isOpen?: boolean
  account?: IAccount
  showAccountSelector?: boolean
}

const { actions, reducer } = createSlice({
  name: 'manageAccountPanel',
  initialState: {} as IManageAccountPanelState,
  reducers: {
    open: (state, action: PayloadAction<{ account?: IAccount }>) => ({
      ...state,
      isOpen: true,
      account: action.payload.account,
      showAccountSelector: !action.payload.account
    }),
    close: () => ({
      isOpen: false
    }),
    setAccount: (state, action: PayloadAction<IAccount>) => ({
      ...state,
      account: action.payload
    })
  }
})

export { reducer as manageAccountPanelReducer }

const rootSelector = (state: AppState) =>
  state.features.manageAccount.manageAccountPanel

const getIsOpen = (state: AppState) => rootSelector(state).isOpen
const getAccount = (state: AppState) => rootSelector(state).account
const getShowAccountSelector = (state: AppState) =>
  rootSelector(state).showAccountSelector

export const useManageAccountPanel = () => {
  const dispatch = useDispatch()

  const open = useCallback(
    (account?: IAccount) => {
      dispatch(actions.open({ account }))
    },
    [dispatch]
  )

  const close = useCallback(() => {
    dispatch(actions.close())
  }, [dispatch])
  const setAccount = useCallback(
    (account: IAccount) => {
      dispatch(actions.setAccount(account))
    },
    [dispatch]
  )

  return {
    isOpen: useSelector(getIsOpen),
    account: useSelector(getAccount),
    showAccountSelector: useSelector(getShowAccountSelector),
    open,
    close,
    setAccount
  }
}
