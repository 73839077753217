import { combineReducers } from 'redux'
import { accountSearchReducer, accountSearchSagas } from './accountSearch'
import { manageAccountPanelReducer } from './manageAccountPanel'

export const manageAccountReducer = combineReducers({
  manageAccountPanel: manageAccountPanelReducer,
  accountSearch: accountSearchReducer
})

export const manageAccountFeatureSagas = [...accountSearchSagas]
