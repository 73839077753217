import { ISearchResult } from 'api/common.types'
import { IOrderBy } from 'api/odata.types'
import { flow } from 'lodash/fp'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'
import { IAccount } from '../../../api/account.types'
import { AppState } from '../../../store'
import { createAsyncReducer } from '../../../store/shared/asyncStore'
import { search } from '../../../store/shared/sagas'

export const accountSearchActions = createAsyncAction(
  '@features/@manageAccount/@accountSearch/REQUEST',
  '@features/@manageAccount/@accountSearch/SUCCESS',
  '@features/@manageAccount/@accountSearch/FAILURE'
)<string, ISearchResult<IAccount>, Error>()

export const accountSearchReducer = createAsyncReducer(accountSearchActions)

export const getAccountSearchState = (state: AppState) =>
  state.features.manageAccount.accountSearch
export const getAccountSearchResult = flow(
  getAccountSearchState,
  (x) => x.result
)

const fetchAccounts = function* (
  action: ReturnType<typeof accountSearchActions.request>
) {
  if (!action.payload) {
    return
  }
  yield delay(100)
  try {
    const result = yield* call(search, 'account' as const, {
      top: 20,
      fullQuery: true,
      count: true,
      query: action.payload,
      searchFields: [
        'CustodyAccount',
        'AdvisorAddedNickName',
        'LegalEntityName',
        'householdName',
        'gfoCustodyAccount'
      ],
      select: [
        'ClientAdvisorID',
        'ClientAdvisor',
        'ClientAdvisorTeam',
        'CustodyAccount',
        'LegalEntityName',
        'LegalEntityID',
        'AccountKPIs/AccountTotal',
        'AdvisorAddedNickName',
        'Shortname',
        'CustodianType',
        'CustodianName',
        'registrationtype',
        'gfoCustodyAccount',
        'id',
        'registrationDesc',
        'RockConnectAccBalance',
        'accountStatus'
      ],
      orderBy: [
        { dataPath: 'search.score()', direction: 'desc' },
        { dataPath: 'RockConnectAccBalance', direction: 'desc' },
        { dataPath: 'AccountKPIs/AccountTotal', direction: 'desc' }
      ] as IOrderBy[]
    })
    const accounts = result?.value as IAccount[]

    if (!accounts?.length) {
      throw new Error('Accounts not found')
    }

    yield put(accountSearchActions.success(result as ISearchResult<IAccount>))
  } catch (e: any) {
    yield put(accountSearchActions.failure(e))
  }
}

export const accountSearchSagas = [
  () => takeLatest(accountSearchActions.request, fetchAccounts)
]
