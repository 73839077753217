import Admin from './Admin'
import Advisory from './Advisory'
import AIRevenue from './AIRevenue'
import Compliance from './Compliance'
import Components from './Components'
import ExternalManagers from './ExternalManagers'
import Fees from './Fees'
import Firm from './Firm'
import GFO from './GFO'
import Home from './Home'
import Households from './Households'
import Login from './Login'
import ManageAccount from './ManageAccount'
import Performance from './Operations/modules/Performance'
import OTP from './OTP'
import Payments from './Payments'
import Products from './Products'
import Reports from './Reports'
import Security from './Security'
import Teams from './Teams'
import Transfers from './Transfers'
import Trust from './Trusts'
export * from './store'

export default [
  Advisory,
  Home,
  Login,
  Payments,
  Fees,
  Trust,
  OTP,
  GFO,
  Households,
  Reports,
  Compliance,
  Products,
  Transfers,
  ManageAccount,
  Admin,
  Teams,
  Security,
  Performance,
  ExternalManagers,
  Firm,
  AIRevenue,
  Components
]
