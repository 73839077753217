import {
  ActionButton,
  Dropdown,
  IDropdownOption,
  Label,
  Stack
} from '@fluentui/react'
import { useCallback, useMemo, useRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import {
  useLazyDeleteDocumentQuery,
  useLazyFetchDocumentQuery,
  useLazyStoreDocumentQuery
} from './api/documentsApi'
import { IDocItem, IRestriction } from './store/types'
import { b64toBlob } from './Utilities'

const RestrictionDocuments: React.FC = () => {
  const { control, getValues, setValue, watch } = useFormContext<IRestriction>()
  const inputFile = useRef<HTMLInputElement>(null)
  const docList = watch('docInfo')
  const selectedRestriction = watch('selectedRestriction')
  const [storeDocument] = useLazyStoreDocumentQuery()
  const [viewDocument] = useLazyFetchDocumentQuery()
  const [deleteDocument] = useLazyDeleteDocumentQuery()

  const docTypeOptions = useMemo(() => {
    const list =
      selectedRestriction?.docMetadata?.map((item) => {
        return {
          key: item?.documentType,
          text: item?.documentType,
          data: item
        } as IDropdownOption
      }) || []
    const defaultItem = {
      key: 'Other',
      text: 'Other',
      data: { idDocument: '', documentType: 'Other' }
    }
    list.push(defaultItem)
    setValue('selectedDocType', list[0]?.data)
    return list
  }, [selectedRestriction?.docMetadata, setValue])

  const handleFileUpload = useCallback(
    async (e: any) => {
      const { files } = e.target
      if (files?.length) {
        const { data, isSuccess } = await storeDocument({
          filecontents: files[0],
          channel: 'Restriction',
          doctype: getValues('selectedDocType') || {}
        })
        const documents = getValues('docInfo') || []
        if (isSuccess) {
          documents?.push(data)
          setValue('docInfo', documents)
        }
      }
      e.target.value = null
    },
    [getValues, storeDocument, setValue]
  )

  const handleViewDoc = useCallback(
    async (document?: IDocItem) => {
      if (document?.storageKey) {
        const { data, isSuccess } = await viewDocument({
          storagedockey: document?.storageKey,
          storagedocid: document?.storageDocId
        })
        if (isSuccess) {
          const blob = b64toBlob(data)
          if (blob) {
            const fileURL = URL.createObjectURL(blob)
            window.open(fileURL, 'DocumentWindow')
          }
        }
      }
    },
    [viewDocument]
  )
  const handleDeleteDoc = useCallback(
    (document?: IDocItem) => {
      if (document?.storageKey) {
        deleteDocument({
          storagedockey: document?.storageKey,
          storagedocid: document?.storageDocId
        })
        const documents = getValues('docInfo') || []
        const newList = documents?.filter(
          (doc) => doc?.storageKey !== document?.storageKey
        )
        setValue('docInfo', newList)
      }
    },
    [deleteDocument, getValues, setValue]
  )

  const onButtonClick = useCallback(() => {
    if (inputFile && inputFile.current !== null) {
      inputFile.current.click()
    }
  }, [])

  const validateDocs = useCallback(() => {
    const uploadedDocs = getValues('docInfo')
    const unUploadedDocs: string[] = []
    selectedRestriction?.docMetadata?.forEach((doc) => {
      if (
        doc?.isRequired &&
        !uploadedDocs?.find(
          (item) => item?.idDocument?.toString() === doc?.idDocument?.toString()
        )
      ) {
        unUploadedDocs.push(doc?.documentType || '')
      }
    })

    if (unUploadedDocs?.length) {
      return `Please upload the mandatory document(s), ${unUploadedDocs?.join(
        ', '
      )}`
    }
    return true
  }, [getValues, selectedRestriction?.docMetadata])
  return (
    <>
      <Controller
        name="docInfo"
        control={control}
        rules={{
          validate: {
            isMandatoryDocsUploaded: () => {
              return validateDocs()
            }
          }
        }}
        render={({ fieldState: { error } }) => (
          <>
            <Label style={{ marginTop: 30 }}>Documents</Label>
            <span
              style={{
                fontSize: 12,
                fontWeight: 400,
                color: 'rgb(164, 38, 44)'
              }}
            >
              {error?.message}
            </span>
          </>
        )}
      />
      <Controller
        name="selectedDocType"
        control={control}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Stack
            horizontal={true}
            tokens={{ childrenGap: 10 }}
            style={{ alignContent: 'center' }}
          >
            <Dropdown
              placeholder="Document Type"
              label="Document Type"
              styles={{ dropdown: { width: 300 } }}
              options={docTypeOptions}
              selectedKey={value?.documentType || ''}
              errorMessage={error?.message}
              onChange={(_e, option) => {
                onChange(option?.data)
              }}
            />
            <div>
              <input
                style={{ display: 'none', paddingTop: '50px' }}
                accept=".pdf"
                ref={inputFile}
                onChange={handleFileUpload}
                type="file"
              />
              <ActionButton
                onClick={onButtonClick}
                style={{ paddingTop: '43px', cursor: 'pointer' }}
                iconProps={{ iconName: 'upload' }}
                title="Upload document"
                label="Upload"
              />
            </div>
          </Stack>
        )}
      />
      <Label>Allowed file types: pdf</Label>
      <Stack horizontal={true} style={{ marginBottom: '2px' }}>
        <Label style={{ width: '10px' }}>#</Label>
        <Label style={{ width: '270px' }}>Document Type</Label>
        <Label style={{ width: '230px' }}>File Name</Label>
        <Label style={{ width: '100px' }}>Actions</Label>
      </Stack>
      {docList?.map((doc, index: number) => {
        return (
          <Stack key={doc?.storageKey} horizontal={true}>
            <Label style={{ width: '10px', fontWeight: 'normal' }}>
              {index + 1}
            </Label>
            <Label style={{ width: '270px', fontWeight: 'normal' }}>
              {doc?.documentType}
            </Label>
            <Label style={{ width: '230px', fontWeight: 'normal' }}>
              {doc?.docName}
            </Label>
            <div>
              <ActionButton
                iconProps={{ iconName: 'view' }}
                onClick={() => {
                  handleViewDoc(doc)
                }}
              />

              <ActionButton
                iconProps={{ iconName: 'delete' }}
                onClick={() => {
                  handleDeleteDoc(doc)
                }}
              />
            </div>
          </Stack>
        )
      })}
    </>
  )
}
export default RestrictionDocuments
