import { Link, Stack, Text } from '@fluentui/react'
import { InvitedEmailCell } from 'features/Clients/InvitedEmailCell'
import React from 'react'
import { FormattedDate } from 'react-intl'
import { IClient } from '../../../api/client.types'
import { parseDateISOStringInLocalTimezone } from '../../../shared'
import { getMostRecentLoginDetails } from '../../../shared/client'
import { LegalEntityNameCellContainer } from '../../Clients/LegalEntityNameCellContainer'
import { AusCell } from '../core/components/AusCell'
import { DateCell } from '../core/components/DateCell'
import { DateOnlyCell } from '../core/components/DateOnlyCell'
import { IListsCellComponent } from '../core/components/ListsDataTable'
import { TextCell } from '../core/components/TextCell'
import { USDCell } from '../core/components/USDCell'
import { AssetAllocationBarContainer } from '../shared/AssetAllocationBarContainer'
import { ProductAllocationBarContainer } from '../shared/ProductAllocationBarContainer'
import { ClientColumnIds } from './ColumnDefinitions'

export const createClientCell = (cellComponents: {
  [key: string]: React.FC<{ client: IClient }>
}) => {
  const ClientCellComponent: IListsCellComponent<IClient> = ({
    columnDefinition,
    item
  }) => {
    const Cell = cellComponents[columnDefinition.id]
    return Cell && <Cell client={item} />
  }
  return ClientCellComponent
}

const MailTo: React.FC<{ email?: string }> = ({ email }) =>
  email ? <Link href={`mailto:${email}`}>{email}</Link> : null

const Tel: React.FC<{ tel?: string }> = ({ tel }) =>
  tel ? (
    <Link
      styles={{ root: { textOverflow: 'ellipsis', overflow: 'hidden' } }}
      href={`tel:${tel}`}
    >
      {tel}
    </Link>
  ) : null

export const ClientCellComponents: {
  [key: string]: React.FC<{ client: IClient }>
} = {
  [ClientColumnIds.team]: ({ client }) => (
    <TextCell>{client.ClientAdvisorTeam}</TextCell>
  ),
  [ClientColumnIds.clientAdvisor]: ({ client }) => (
    <TextCell>{client.ClientAdvisor}</TextCell>
  ),
  [ClientColumnIds.clientAdvisorId]: ({ client }) => (
    <TextCell>{client.ClientAdvisorID}</TextCell>
  ),
  [ClientColumnIds.aum]: ({ client }) => (
    <USDCell value={client.ClientKPI?.AumManaged} fractionDigits={0} />
  ),
  [ClientColumnIds.aus]: ({ client }) => <AusCell KPI={client.ClientKPI} />,
  [ClientColumnIds.brokerageAssets]: ({ client }) => (
    <USDCell value={client.ClientKPI?.AumBrokerage} fractionDigits={0} />
  ),
  [ClientColumnIds.purchasingPower]: ({ client }) => (
    <USDCell value={client.ClientKPI?.cashAvlToTrade} fractionDigits={0} />
  ),
  [ClientColumnIds.loansOutstanding]: ({ client }) => (
    <USDCell value={client.ClientKPI?.LoanOutstanding} fractionDigits={0} />
  ),
  [ClientColumnIds.netNewMoney]: ({ client }) => (
    <USDCell value={client.ClientKPI?.NetNewMoney} fractionDigits={0} />
  ),
  [ClientColumnIds.netNewAssets]: ({ client }) => (
    <USDCell value={client.ClientKPI?.TOA} fractionDigits={0} />
  ),
  [ClientColumnIds.annuity]: ({ client }) => (
    <USDCell value={client.ClientKPI?.annuity} fractionDigits={0} />
  ),
  [ClientColumnIds.legalEntityName]: ({ client }) => (
    <LegalEntityNameCellContainer client={client} />
  ),
  [ClientColumnIds.invitedEmail]: ({ client }) => (
    <InvitedEmailCell client={client} />
  ),
  [ClientColumnIds.wealthscapeId]: ({ client }) =>
    client?.loginDetails?.length ? (
      <Stack>
        {client.loginDetails
          .filter((x) => x.WealthscapeID)
          .map((x) => (
            <TextCell key={x?.WealthscapeID}>{x.WealthscapeID}</TextCell>
          ))}
      </Stack>
    ) : null,
  [ClientColumnIds.wealthscapeEmail]: ({ client }) => (
    <TextCell>
      <MailTo email={client?.contactdetails?.emailid} />
    </TextCell>
  ),
  [ClientColumnIds.wealthscapePhone]: ({ client }) => {
    const phones = client?.contactdetails?.phones?.filter(
      ({ telNumber }) => telNumber
    )

    if (!phones?.length) {
      return <TextCell>--</TextCell>
    }

    return (
      <Stack styles={{ root: { minWidth: 0 } }}>
        {phones?.map(({ telNumber, telType }) => (
          <Stack
            key={telNumber}
            horizontal={true}
            verticalAlign="center"
            tokens={{ childrenGap: 5 }}
            styles={{ root: { minWidth: 0 } }}
          >
            <Tel tel={telNumber} />
            {telType && (
              <Text
                variant="small"
                nowrap={true}
                styles={{ root: { fontWeight: 'bold' } }}
              >
                ({telType})
              </Text>
            )}
          </Stack>
        ))}
      </Stack>
    )
  },
  [ClientColumnIds.dateOfBirth]: ({ client }) => {
    /*
     * DOB comes as an ISO 8601 date string with time as 00.00.00Z.  DOB shouldn't include the time,
     * but azure search doesn't support date only columns.  When parsing this in javascript
     * we need to be careful to only consider the date portion and not the time as that could
     * result in showing an incorrect date.  This happens because javascript will parse the date
     * in the current timezone.
     */
    const date = client.dateofBirth
      ? parseDateISOStringInLocalTimezone(client.dateofBirth)
      : undefined

    return date ? (
      <Text nowrap={true}>
        <FormattedDate
          day="2-digit"
          month="2-digit"
          year="numeric"
          value={date}
        />
      </Text>
    ) : (
      <Text>--</Text>
    )
  },
  [ClientColumnIds.lastLogin]: ({ client }) => {
    const { lastLogin } =
      getMostRecentLoginDetails(
        client.loginDetails?.filter((x) => x.loginid)
      ) || {}

    return lastLogin ? <DateCell value={lastLogin} /> : null
  },

  [ClientColumnIds.lastUpdated]: ({ client }) => (
    <DateCell value={client.LastUpdatedAt} />
  ),
  [ClientColumnIds.productAllocation]: ({ client }) => (
    <ProductAllocationBarContainer
      productAllocations={client?.productAllocation || []}
    />
  ),
  [ClientColumnIds.assetAllocation]: ({ client }) => (
    <AssetAllocationBarContainer
      assetAllocations={client?.assetAllocationLvl1 || []}
    />
  ),
  [ClientColumnIds.srcClientNumber]: ({ client }) => (
    <TextCell>{client.srcClientNumber}</TextCell>
  ),
  [ClientColumnIds.taxState]: ({ client }) => (
    <TextCell>{client?.TaxState}</TextCell>
  ),
  [ClientColumnIds.familyGroupName]: ({ client }) => (
    <TextCell>{client?.householdName}</TextCell>
  ),
  [ClientColumnIds.isEnrolledInEauth]: ({ client }) => (
    <TextCell>{client.isEnrolledInEauth || '--'}</TextCell>
  ),
  [ClientColumnIds.isEnrolledInMFA]: ({ client }) => (
    <TextCell>{client.isEnrolledInMFA || '--'}</TextCell>
  ),
  [ClientColumnIds.accountant]: ({ client }) => (
    <TextCell>{client?.ClientAccountant}</TextCell>
  ),
  [ClientColumnIds.entityType]: ({ client }) => (
    <TextCell>{client?.legalEntityType}</TextCell>
  ),
  [ClientColumnIds.cashBalances]: ({ client }) => (
    <USDCell value={client?.ClientKPI?.cashAvlToTrade} />
  ),
  [ClientColumnIds.householdId]: ({ client }) => (
    <TextCell>{client?.householdId}</TextCell>
  ),
  [ClientColumnIds.householdName]: ({ client }) => (
    <TextCell>{client?.householdName}</TextCell>
  ),
  [ClientColumnIds.revenueMTD]: ({ client }) => (
    <USDCell value={client?.revenueDet?.MTDcompRevenue} fractionDigits={0} />
  ),
  [ClientColumnIds.revenueQTD]: ({ client }) => (
    <USDCell value={client?.revenueDet?.QTDcompRevenue} fractionDigits={0} />
  ),
  [ClientColumnIds.revenueYTD]: ({ client }) => (
    <USDCell value={client?.revenueDet?.YTDcompRevenue} fractionDigits={0} />
  ),
  [ClientColumnIds.revenueT12]: ({ client }) => (
    <USDCell value={client?.revenueDet?.ttmrevenue} fractionDigits={0} />
  ),
  [ClientColumnIds.accreditedInvestor]: ({ client }) => (
    <TextCell>{client?.accreditedInvestor}</TextCell>
  ),
  [ClientColumnIds.qualifiedClient]: ({ client }) => (
    <TextCell>{client?.qualifiedClient}</TextCell>
  ),
  [ClientColumnIds.qualifiedPurchaser]: ({ client }) => (
    <TextCell>{client?.qualifiedPurchaser}</TextCell>
  ),
  [ClientColumnIds.partyStatus]: ({ client }) => (
    <TextCell>{client?.partyStatus}</TextCell>
  ),
  [ClientColumnIds.legalEntityId]: ({ client }) => (
    <TextCell>{client?.LegalEntityID}</TextCell>
  ),
  [ClientColumnIds.partyUniqueId]: ({ client }) => (
    <TextCell>{client?.PartyUniqueId}</TextCell>
  ),
  [ClientColumnIds.liquidAssets]: ({ client }) => (
    <USDCell value={client?.LiquidAssets} fractionDigits={0} />
  ),
  [ClientColumnIds.netWorth]: ({ client }) => (
    <USDCell value={client?.NetWorth} fractionDigits={0} />
  ),
  [ClientColumnIds.AdvisedOnly]: ({ client }) => (
    <USDCell value={client.ClientKPI?.AdvisedOnly} fractionDigits={0} />
  ),
  [ClientColumnIds.LoanOutstandingOutsideNfs]: ({ client }) => (
    <USDCell
      value={client.ClientKPI?.LoanOutstandingOutsideNfs}
      fractionDigits={0}
    />
  ),
  [ClientColumnIds.LoanOutstandingNfs]: ({ client }) => (
    <USDCell value={client.ClientKPI?.LoanOutstandingNfs} fractionDigits={0} />
  ),
  [ClientColumnIds.AdminReporting]: ({ client }) => (
    <USDCell
      value={client.ClientKPI?.AdminReportingAssets}
      fractionDigits={0}
    />
  ),
  [ClientColumnIds.RockConnectAccBalance]: ({ client }) => (
    <USDCell value={client.RockConnectAccBalance} fractionDigits={0} />
  ),
  [ClientColumnIds.Office]: ({ client }) => (
    <TextCell>{client?.HubName}</TextCell>
  ),
  [ClientColumnIds.BusinessSegment]: ({ client }) => (
    <TextCell>{client?.BusinessSegment}</TextCell>
  ),
  [ClientColumnIds.Division]: ({ client }) => (
    <TextCell>{client?.RegionName}</TextCell>
  ),
  [ClientColumnIds.PrimaryCA]: ({ client }) => (
    <TextCell>{client?.PrimaryCA}</TextCell>
  ),
  [ClientColumnIds.PrimaryPA]: ({ client }) => (
    <TextCell>{client?.PrimaryPA}</TextCell>
  ),
  [ClientColumnIds.TrustCompany]: ({ client }) => (
    <TextCell>{client?.TrustCompany}</TextCell>
  ),
  [ClientColumnIds.assets_liabilities]: ({ client }) => (
    <USDCell value={client.ClientKPI?.AssetsLiabilities} fractionDigits={0} />
  ),
  [ClientColumnIds.extAccountAmount]: ({ client }) => (
    <USDCell value={client.ClientKPI?.extAccountAmount} fractionDigits={0} />
  ),
  [ClientColumnIds.establishedDate]: ({ client }) => (
    <DateOnlyCell value={client?.establishedDate} />
  )
}

export const ClientCell = createClientCell(ClientCellComponents)
