import {
  Callout,
  DirectionalHint,
  Stack,
  TextField,
  Text,
  ITheme
} from '@fluentui/react'
import { IAccount } from 'api/account.types'
import { AccountSearchResults } from 'features/Search/components/AccountSearchResults'
import { SearchTotalDivider } from 'features/Search/components/SearchTotalDivider'
import React, { useCallback, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useClasses } from 'shared/hooks/useClasses'
import {
  accountSearchActions,
  getAccountSearchResult
} from './store/accountSearch'
import { useManageAccountPanel } from './store/manageAccountPanel'
import { IRestriction } from './store/types'

const getThemedClasses = (theme: ITheme) => {
  return {
    searchResults: {
      padding: 0,
      margin: 0,
      borderCollapse: 'collapse',
      tableLayout: 'fixed',
      width: 'auto',
      minWidth: '200px',
      selectors: {
        '& tr:hover': {
          backgroundColor: theme.palette.neutralLighter
        },
        tr: {
          cursor: 'pointer'
        },
        td: {
          padding: '5px',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        },
        '& td:nth-child(1)': {
          width: '200px'
        },
        '& td:nth-child(2)': {
          width: '150px'
        },
        '& td:nth-child(3)': {
          verticalAlign: 'top',
          textAlign: 'right'
        }
      }
    },
    seperator: {
      flexGrow: 1,
      position: 'relative',
      selectors: {
        div: {
          backgroundColor: theme.palette.neutralDark,
          height: '1px',
          content: '',
          display: 'block',
          position: 'absolute',
          top: '50%',
          bottom: '0px',
          left: '0px',
          right: '0px'
        }
      }
    }
  }
}

const AccountSelection: React.FC = () => {
  const reduxDispatch = useDispatch()
  const { setAccount } = useManageAccountPanel()
  const classNames = useClasses(getThemedClasses)
  const { control, watch, reset, setValue } = useFormContext<IRestriction>()
  const accountSearchText = watch('accountSearchText')
  const [isAccountSearchCalloutOpen, setIsAccountSearchCalloutOpen] =
    useState(false)
  const openAccountSearchCallout = () => setIsAccountSearchCalloutOpen(true)
  const closeAccountSearchCallout = () => setIsAccountSearchCalloutOpen(false)
  const searchAccountContainer = useRef<HTMLDivElement>(null)
  const accountSearchList = useSelector(getAccountSearchResult)

  const clearPreviousstate = useCallback(async () => {
    reset()
  }, [reset])

  const handleAccountSearch = useCallback(
    (accountText?: string) => {
      if (accountText) {
        reduxDispatch(accountSearchActions.request(accountText))
      }
    },
    [reduxDispatch]
  )

  const onAccountSelected = useCallback(
    async (account: IAccount) => {
      await clearPreviousstate()
      setValue('accountSearchText', account.CustodyAccount)
      setAccount(account)
    },
    [clearPreviousstate, setValue, setAccount]
  )

  return (
    <div>
      <div ref={searchAccountContainer}>
        <Controller
          name="accountSearchText"
          control={control}
          rules={{ required: 'Account is required' }}
          render={({
            fieldState: { error },
            field: { value, onChange, onBlur }
          }) => (
            <TextField
              label="Account Search"
              placeholder="Search an account"
              required={true}
              value={value || ''}
              errorMessage={error?.message}
              onBlur={onBlur}
              autoComplete="new-password"
              list="autoCompleteOff"
              onChange={(_e, newValue) => {
                onChange(newValue)
                handleAccountSearch(newValue)
              }}
              onFocus={() => {
                openAccountSearchCallout()
                handleAccountSearch(value)
              }}
              onClick={openAccountSearchCallout}
            />
          )}
        />
      </div>

      {isAccountSearchCalloutOpen && (
        <Callout
          styles={{
            root: {
              zIndex: 3,
              width: 'auto',
              minWidth: '600px',
              marginTop: '5px'
            }
          }}
          target={searchAccountContainer}
          isBeakVisible={false}
          setInitialFocus={true}
          directionalHint={DirectionalHint.bottomAutoEdge}
          doNotLayer={true}
          onDismiss={closeAccountSearchCallout}
        >
          <Stack
            onClick={closeAccountSearchCallout}
            styles={{
              root: {
                padding: 5,
                maxWidth: '600px',
                overflowY: 'auto',
                maxHeight: 'calc(100vh - 250px)',
                paddingBottom: '10px'
              }
            }}
          >
            <SearchTotalDivider
              className={classNames.seperator}
              title="Accounts"
              count={accountSearchList?.value.length || 0}
              total={accountSearchList?.['@odata.count'] || 0}
            />

            {accountSearchList?.value?.length ? (
              <AccountSearchResults
                accounts={accountSearchList?.value || []}
                className={classNames.searchResults}
                onAccountClick={onAccountSelected}
                query={accountSearchText}
              />
            ) : (
              <Text
                styles={{
                  root: { textAlign: 'center', padding: '20px' }
                }}
                variant="small"
              >
                No Results
              </Text>
            )}
          </Stack>
        </Callout>
      )}
    </div>
  )
}

export default AccountSelection
