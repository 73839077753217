import {
  DatePicker,
  Dropdown,
  IDropdownOption,
  Label,
  Stack,
  TextField
} from '@fluentui/react'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { USD } from 'shared/components/Formatting'
import AccountSelection from './AccountSelection'
import { useFetchRestrictionsQuery } from './api/restrictionApi'
import RestrictionDocuments from './ResrictionDocuments'
import { useManageAccountPanel } from './store/manageAccountPanel'
import { IRestriction } from './store/types'

const RestrictionSubmitForm: React.FC = () => {
  const { account, showAccountSelector } = useManageAccountPanel()
  const { data: restrictionList } = useFetchRestrictionsQuery(
    account?.CustodyAccount || ''
  )

  const { control, watch, setValue } = useFormContext<IRestriction>()
  const selectedRestriction = watch('selectedRestriction')

  const auxFieldConfig = useMemo(() => {
    return selectedRestriction?.auxFields?.[0]
  }, [selectedRestriction?.auxFields])
  const RestrictionOptions = useMemo(() => {
    return restrictionList?.map(
      (item) =>
        ({
          key: item?.restrictionAction?.idRestrictionAction,
          text:
            item?.description +
            ' (' +
            item?.restrictionAction?.code +
            ' - ' +
            item?.restrictionAction?.action +
            ')',
          data: item
        } as IDropdownOption)
    )
  }, [restrictionList])
  return (
    <form>
      {showAccountSelector && <AccountSelection />}

      <Stack horizontal={true} tokens={{ childrenGap: 30 }} wrap>
        {/* {!showAccountSelector && ( */}
        <div>
          <Label>Account Number</Label>
          <div>{account?.CustodyAccount}</div>
        </div>
        {/* )} */}

        <div>
          <Label>Client Name</Label>
          <div>{account?.LegalEntityName}</div>
        </div>
        <div>
          <Label>Account Balance</Label>
          <USD
            value={
              account?.RockConnectAccBalance ||
              account?.AccountKPIs?.AccountTotal ||
              0
            }
          />
        </div>
      </Stack>

      <Controller
        name="selectedRestriction"
        control={control}
        rules={{
          required: 'Restriction Code is required'
        }}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <Dropdown
            placeholder="Restriction Code"
            label="Restriction Code"
            options={RestrictionOptions || []}
            selectedKey={value?.restrictionAction?.idRestrictionAction || ''}
            required={true}
            errorMessage={error?.message}
            onChange={async (_e, option) => {
              await setValue('auxField', undefined)
              onChange(option?.data)
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="reason"
        rules={{
          required: `Reason is required`
        }}
        render={({ fieldState: { error }, field: { value, onChange } }) => (
          <div>
            <TextField
              name="reason"
              placeholder="Reason"
              required={true}
              label="Reason"
              ariaLabel="Reason"
              maxLength={2500}
              autoComplete="new-password"
              list="autoCompleteOff"
              value={value || ''}
              errorMessage={error?.message}
              onChange={onChange}
              multiline={true}
            />
          </div>
        )}
      />
      <Controller
        control={control}
        name="notes"
        render={({ fieldState: { error }, field: { value, onChange } }) => (
          <div>
            <TextField
              name="notes"
              placeholder="Notes"
              label="Notes"
              ariaLabel="notes"
              maxLength={2500}
              autoComplete="new-password"
              list="autoCompleteOff"
              value={value || ''}
              errorMessage={error?.message}
              onChange={onChange}
              multiline={true}
            />
          </div>
        )}
      />
      {auxFieldConfig?.name && (
        <Controller
          name="auxField"
          control={control}
          rules={{
            required: auxFieldConfig?.isRequired
              ? `${auxFieldConfig?.name} is required`
              : false
          }}
          render={({
            field: { value, onChange, onBlur, name: fieldName },
            fieldState: { error }
          }) => (
            <>
              {auxFieldConfig?.type?.toLowerCase() === 'date' && (
                <DatePicker
                  label={auxFieldConfig?.name || 'Date'}
                  placeholder={auxFieldConfig?.name || 'Date'}
                  isRequired={auxFieldConfig?.isRequired}
                  value={value as Date | undefined}
                  today={new Date()}
                  maxDate={new Date()}
                  onSelectDate={onChange}
                  onBlur={onBlur}
                  textField={{
                    name: fieldName,
                    errorMessage: error?.message
                  }}
                  formatDate={(date) =>
                    date ? format(date, 'MM/dd/yyyy') : ''
                  }
                />
              )}
              {auxFieldConfig?.type?.toLowerCase() === 'string' && (
                <TextField
                  name="fraudCaseNo"
                  placeholder={auxFieldConfig?.name || 'Fraud Case Number'}
                  required={auxFieldConfig?.isRequired}
                  label={auxFieldConfig?.name || 'Fraud Case #'}
                  ariaLabel={auxFieldConfig?.name || 'Fraud Case Number'}
                  autoComplete="new-password"
                  list="autoCompleteOff"
                  maxLength={500}
                  value={(value as string | undefined) || ''}
                  errorMessage={error?.message}
                  onChange={onChange}
                />
              )}
            </>
          )}
        />
      )}

      <RestrictionDocuments />
    </form>
  )
}

export default RestrictionSubmitForm
